import React from 'react'
import { MainImage } from '../assests/images'

export const Banner = () => {
  return (
    <div className="container-fluid g-0 visually-hidden">
    <div className="row g-0">
      <div className="col-md-12 col-12">
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              {/*<img src={MainImage.default} className="w-100" alt="..." />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

