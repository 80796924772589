import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import HomeService from "../../axios/services/HomeService";
// import parse from "html-react-parser";

export const HomeBody = () => {
  const [home, setHome] = useState({});
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const getJob = async () => {
      try {
        const { data } = await HomeService.getHome();
        setHome(data);
      } catch (error) {
        console.log(error);
      }
    };
    getJob();
  }, []);

  if (home && Object.keys(home).length === 0) {
    return <Loading />;
  }
  if (home && Object.keys(home).length !== 0) {
    return <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 col-12">
          <h1 className="sectionName">Inicio</h1>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: home.pageable.body }} />
        </div>
      </div>
    </div>
  }
  if (home === null) {
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12 col-12">
            <h1 className="sectionName">Inicio</h1>
            <hr />
            <h1 className="mt-4 mb-3 text-center homeTitle">
              FERIA NACIONAL DE EDUCACIÓN, ARTES, CIENCIAS Y TECNOLOGÍA
            </h1>
            <h4 className="text-center subtitleHome">
              Edición 2024
            </h4>
            <br></br>
            <p className="home">
              La Secretaría de Educación de la Nación les da la bienvenida a la Feria Nacional de Educación, Artes, Ciencias y Tecnología 2024.
            </p>
            <p className="home">
              Los invitamos a descubrir y sorprenderse con los proyectos realizados por estudiantes comprometidos con la indagación escolar sobre focos científico-tecnológicos, matemáticos, humanistas y artísticos. Al recorrer el evento encontrarán trabajos que surgieron de las problemáticas que los niños, jóvenes y adolescentes perciben de su entorno.
            </p>
            <p className="home">
              Las Ferias de Ciencias proponen desafíos que nacen en las aulas de todo el país, se presentan en todos los niveles y modalidades de la educación, y se prolongan durante todo el ciclo lectivo en las instancias de ferias escolares, zonales, provinciales hasta llegar a la instancia nacional. Se movilizan y comprometen miles de instituciones y decenas de miles de estudiantes y docentes en todo el país, a los que se suman sus familias y toda la comunidad.
            </p>
            <p className="home">
              Los proyectos que se exhiben son impulsados desde las escuelas. Promueven la formación de una ciudadanía activa y plena, mediante el ejercicio del derecho a organizarse, expresarse y aprender a construir sentidos comunes a partir de la diversidad de opiniones.
            </p>
            <p className="home">
              El Programa Nacional de Ferias de Ciencias y Tecnología forma parte del Área de Actividades Científicas de la Dirección Nacional de Inclusión y Extensión Educativa, de la Subsecretaría de Políticas e Innovación Educativa. El trabajo que lleva adelante la Dirección, a través de esta y otras políticas educativas, busca fortalecer las trayectorias educativas de los estudiantes y ofrecer diversas propuestas pedagógicas para la ampliación de sus universos culturales.
            </p>
            <p className="home">
              El Programa Nacional elaboró para esta nueva edición de Ferias de Ciencias una propuesta pedagógica basada en la estrategia STEAM, adaptada a los niveles y modalidades educativas, y al contexto escolar y de la comunidad donde se genera cada proyecto. Este enfoque prioriza la actividad interdisciplinaria para la resolución de problemas y ofrece conocimientos específicos de cada campo de saberes promoviendo habilidades y capacidades en un permanente “ecosistema” de aprendizaje.
            </p>
            <p className="home">
              En función de esta estrategia STEAM, la instancia nacional de ferias se ha organizado este año en cuatro fases:<br />
              <br />
              <ul>
                <li className="mb-4">
                  Fase 1 – Eje Artístico: 24 al 28 de septiembre, San Miguel de Tucumán, provincia de Tucumán
                </li>
                <li className="mb-4">
                  Fase 2 - Eje Matemático: 21 al 25 de octubre, Mar del Plata, provincia de Buenos Aires
                </li>
                <li className="mb-4">
                  Fase 3 – Eje Científico: 4 al 8 de noviembre, Córdoba, provincia de Córdoba
                </li>
                <li className="mb-4">
                  Fase 4 – Eje Tecnológico: 25 al 29 de noviembre, Ciudad Autónoma de Buenos Aires
                </li>
              </ul>
            </p>
            <br />
            <p className="home">
              Esperamos que disfruten de este espacio de construcción federal, participación, reconocimiento y de acción. Para nosotros es fundamental que la comunidad en su conjunto reconozca la tarea que desarrollan los estudiantes y sus docentes, revaloricen sus saberes, sus lenguajes y la creatividad que se expresa en cada proyecto.
            </p>
            <p className="home">
              Continuemos trabajando por una escuela que amplíe el horizonte de posibilidades de nuestros niños, adolescentes y jóvenes.
            </p>
            <br />
            <br />
            <br />
            <div className="d-flex flex-column">
              <div className="_homeText">
                Programa Nacional de Ferias de Ciencias y Tecnología
              </div>
              <div className="_homeText">
                Área de Actividades Científicas
              </div>
              <div className="_homeText">
                Dirección Nacional de Inclusión y Extensión Educativa
              </div>
              <div className="_homeText">
                Subsecretaría de Políticas e Innovación Educativa
              </div>
              <div className="_homeText">
                Secretaría de Educación
              </div>
              <div className="_homeText">
                <b>Ministerio de Capital Humano de la Nación</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
