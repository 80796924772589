import React from "react";
import { Distinctions } from "../components/distinctions/Distinctions";


export const Distinction = () => {
  return (
     
     <main className="flex-shrink-0" style={{minHeight: '450px'}}>
     </main>
     );
      {/* Se quita hasta que haya distinciones
      <main className="flex-shrink-0" style={{minHeight: '450px'}}>
     <Distinctions/>  
     </main>
     */}
}