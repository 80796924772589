import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const CommentCarousel = ({ comment: {comments} }) => {
  const isMobile = window.innerWidth;
  const Arrow = ({ className, style, onClick }) => (
    <button style={{...style, left: 0, backgroundColor: 'red'}} onClick={onClick} className={className}>
      <div>back</div>
    </button>
  );

  const CustomPrevArrow = (props) => (
    <div
      {...props}
      className="custom-prev-arrow"
      style={{ fontSize: "24px" }}
      // Add your custom styles here
    >
      <i class="bi bi-caret-left-fill" style={{color: '#707070'}}></i>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      {...props}
      className="custom-next-arrow"
      style={{ fontSize: "24px" }}
      // Add your custom styles here
    >
      <i class="bi bi-caret-right-fill" style={{color: '#707070'}}></i>
    </div>
  );
  const settings = {
    infinite: comments?.length < 3 ? false : true,
    slidesToShow: isMobile < 769 ? 1 : 3,
    slidesToScroll: isMobile < 769 ? 1 : 3,
    autoplay: true,
    /* speed: 4600, */
     autoplaySpeed: 3200,
    cssEase: "linear",
    arrows: true,
    className: "slides",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  



  return (
    <div className="root">
      <div className="carousel-container">
        <Slider {...settings}>
          {comments?.map(({ short_name, commentary }, i) => {
            return (
              <div key={i}>
                <p
                  data-tooltip-id="my-tooltip-2"
                  style={{ fontSize: "14px", lineHeight: "25px",wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {commentary.length > 255
                    ? commentary.substring(0, 255) + "..."
                    : commentary}
                </p>
                <p className="fw-bold" style={{ fontSize: "14px" }}>
                  {short_name?.charAt(0).toUpperCase() + short_name?.slice(1)}
                </p>
              </div>
            );
          })}
          
        </Slider>
       
      </div>
    </div>
  );
};
