import api from "../api";

const endpointName = "/schedule";

const ScheduleService = {
  getSchedule: ( page = 1, limit = 12) => {
    return api.get(`${endpointName}?page=${page}&limit=${limit}`);
  },
  getScheduleText: () => {
    return api.get(`${endpointName}/text`)
  }
};

export { ScheduleService as default };
