import React from "react";
import { FacebookImage, InstagramImage, LogoBandera, LogoMined, TwitterImage, YoutubeImage } from "../assests/images";

export const Footer = () => {
  return (
    <footer className="footer mt-5 py-3">
      <div className="container">
        <div className="row d-flex align-items-center mobile">
          <div className="col-md-5 col-12">
            <div className="si-container">
              <a
                href="https://www.facebook.com/MinisteriodeCapitalHumano"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebookImage.default}
                  width="100%"
                  alt="Facebook"
                />
              </a>
              <a
                href="https://x.com/MinCapHum_Ar"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TwitterImage.default}
                  width="100%"
                  alt="X"
                />
              </a>
              <a
                href="https://www.instagram.com/mincapitalhumano/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramImage.default}
                  width="100%"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.youtube.com/@MinCapHum_Ar"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={YoutubeImage.default}
                  width="100%"
                  alt="Youtube"
                />
              </a>
            </div>
          </div>
          
          <div className="col-md-3 col-6 d-flex justify-content-center">
            {/*
            <a href="#home">
              <img
                className="footerLogo bandera"
                src={LogoBandera.default}
                height="100%"
                alt="Logo Bandera"
                style={{ height: "42px"}}
              />
            </a>*/}
          </div>
          <div className="col-md-4 col-6">
            <a
              href="https://www.argentina.gob.ar/educacion"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footerLogo float-end"
                src={LogoMined.default}
                height="100%"
                alt="Logo Ministerio"
              />
              
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
