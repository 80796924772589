import { HomeBody } from "../components/home/HomeBody";

export const Home = () => {
  return (
    <main className="flex-shrink-0">
  
      <HomeBody />
    </main>
  );
};
