import React from "react";
import { ScheduleBody } from "../components/schedules/ScheduleBody";

export const Schedule = () => {
  return (
    <main className="flex-shrink-0">
      <ScheduleBody />
    </main>
  );
};
