import api from "../api";

const endpointName = "/project";

const JobsService = {
  getJobs: (query, page = 1, limit = 12) => {
    return api.get(
      `${endpointName}${query ? `${query}&` : "?"}page=${page}&limit=${limit}`
    );
  },
  getOneJobs: (id) => api.get(`${endpointName}/${id}`),
  commentJobs: (data) => api.post(`/comments`, data),
  postValidateCaptcha: (token) =>
    api.post(`/recaptcha`, {
      "g-recaptcha-response": token,
    }),
};

export { JobsService as default };
