import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import useQuery from "../../hooks/useQuery";

export const JobSearch = ({ jobs }) => {
  const [options, setOptions] = useState({
    fair: [],
    schoolLevel: [],
    jurisdiction: [],
    modality: [],
    typeJob: [],
  });

  const query = useQuery();
  const history = useNavigate();

  useEffect(() => {
    setOptions({
      fair: jobs?.filters?.fair_id,
      schoolLevel: jobs?.filters?.school_level_id,
      jurisdiction: jobs?.filters?.jurisdiction_id,

      modality: jobs?.filters?.grading_modality_id,
      typeJob: jobs?.filters?.project_type_id,
    });
  }, [jobs]);

  return (
    <Formik
      initialValues={{
        fair_id_filter: query.get("fair_id_filter")
          ? query.get("fair_id_filter")
          : "",
        school_level_id_filter: query.get("school_level_id_filter")
          ? query.get("school_level_id_filter")
          : "",
        jurisdiction_id_filter: query.get("jurisdiction_id_filter")
          ? query.get("jurisdiction_id_filter")
          : "",
        grading_modality_id_filter: query.get("grading_modality_id_filter")
          ? query.get("grading_modality_id_filter")
          : "",
        project_type_id_filter: query.get("project_type_id_filter")
          ? query.get("project_type_id_filter")
          : "",
        keywords: query.get("keywords") ? query.get("keywords") : "",
      }}
      onSubmit={(data) => {
        if (!Object.values(data).every((v) => !v)) {
          query.set("fair_id_filter", data.fair_id_filter);
          query.set("school_level_id_filter", data.school_level_id_filter);
          query.set("jurisdiction_id_filter", data.jurisdiction_id_filter);
          query.set(
            "grading_modality_id_filter",
            data.grading_modality_id_filter
          );
          query.set("project_type_id_filter", data.project_type_id_filter);
          query.set("keywords", data.keywords);

          history(`?${query.toString()}`);
        }
      }}
      // onReset={(data, actions) => {
      //   actions.setValues({
      //     school_level_id_filter: "",
      //     jurisdiction_id_filter: "",
      //     grading_modality_id_filter: "",
      //     project_type_id_filter: "",
      //     keywords: "",
      //   });

      //   query.delete("school_level_id_filter", "");
      //   query.delete("jurisdiction_id_filter", "");
      //   query.delete("grading_modality_id_filter", "");
      //   query.delete("project_type_id_filter", "");
      //   query.delete("keywords", "");
      //   history(`?${query.toString()}`);
      // }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        // handleReset,
        setFieldValue,
        isSubmitting,
        touched,
        values,
        ...formikProps
      }) => {
        function handleReset() {
          formikProps.setValues({
            fair_id_filter: "",
            school_level_id_filter: "",
            jurisdiction_id_filter: "",
            grading_modality_id_filter: "",
            project_type_id_filter: "",
            keywords: "",
          });

          query.set("fair_id_filter", "");
          query.set("school_level_id_filter", "");
          query.set("jurisdiction_id_filter", "");
          query.set("grading_modality_id_filter", "");
          query.set("project_type_id_filter", "");
          query.set("keywords", "");
          query.delete("fair_id_filter", "");
          query.delete("school_level_id_filter", "");
          query.delete("jurisdiction_id_filter", "");
          query.delete("grading_modality_id_filter", "");
          query.delete("project_type_id_filter", "");
          query.delete("keywords", "");
          history(`?${query.toString()}`);
        }

        return (
        <Form>
          <div className="row custom-div-form">
            <div className="col-md-2 col-6 mt-2 custom-with">
              <Select
                className="custom-select"
                options={options.fair}
                placeholder="Eje"
                value={options?.fair?.find(
                  (fair) =>
                    fair.id === Number(values.fair_id_filter)
                ) || ''}
                getOptionValue={(option) => String(option.id)}
                getOptionLabel={(option) => String(option.display_name)}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("fair_id_filter", e.id);
                }}
                name="fair_id_filter"
              />
            </div>
            <div className="col-md-2 col-6 mt-2 custom-with">
              <Select
                className="custom-select"
                options={options.schoolLevel}
                placeholder="Nivel educativo"
                value={options?.schoolLevel?.find(
                  (level) =>
                    level.id === Number(values.school_level_id_filter)
                ) || ''}
                getOptionValue={(option) => String(option.id)}
                getOptionLabel={(option) => String(option.display_name)}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("school_level_id_filter", e.id);
                }}
                name="school_level_id_filter"
              />
            </div>
            <div className="col-md-2 col-6 mt-2 custom-with">
              <Select
                className="custom-select"
                options={options?.jurisdiction}
                placeholder="Jurisdicción"
                value={options?.jurisdiction?.find(
                  (jurisdiction) =>
                    jurisdiction.id === Number(values.jurisdiction_id_filter)
                ) || ''}
                getOptionValue={(option) => String(option.id)}
                getOptionLabel={(option) => String(option.display_name)}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("jurisdiction_id_filter", e.id);
                }}
                name="jurisdiction_id_filter"
              />
            </div>
            <div className="col-md-2 col-6 mt-2 custom-with">
              <Select
                className="custom-select"
                options={options?.modality}
                placeholder="Modalidad"
                value={options?.modality?.find(
                  (modality) =>
                    modality.id === Number(values.grading_modality_id_filter)
                ) || ''}
                getOptionValue={(option) => String(option.id)}
                getOptionLabel={(option) => String(option.display_name)}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("grading_modality_id_filter", e.id);
                }}
                name="grading_modality_id_filter"
              />
            </div>
            <div className="col-md-2 col-6 mt-2 custom-with">
              <Select
                className="custom-select"
                options={options?.typeJob}
                placeholder={
                  window.innerWidth < 500
                    ? "Tipos de tr.."
                    : "Tipos de trabajos"
                }
                value={options?.typeJob?.find(
                  (typeJobs) =>
                    typeJobs.id === Number(values.project_type_id_filter)
                ) || ''}
                isLoading={!jobs?.filters?.project_type_id}
                getOptionValue={(option) => String(option.id)}
                getOptionLabel={(option) => String(option.display_name)}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("project_type_id_filter", e.id);
                }}
                name="project_type_id_filter"
              />
            </div>
            <div className="col-md-2 col-12 mt-2 custom-with">
              <div className="col-auto">
                <input
                  type="text"
                  name="keywords"
                  className="form-control custom-form"
                  id="inputPassword2"
                  onChange={(e) => {
                    setFieldValue("keywords", e.target.value);
                  }}
                  placeholder="Buscá por palabra clave"
                  value={values.keywords}
                />
              </div>
            </div>
            <div className="d-flex col-md-2 col-6 mt-2">
              <div className="col-auto">
                <button
                  className="btn btn-secondary mb-3 w-100 btn-search"
                  onClick={handleReset}
                  type="button"
                >
                  Limpiar
                </button>
              </div>
              <div className="col-auto mx-2">
                <button
                  type="submit"
                  className="btn btn-primary mb-3 w-100 btn-search"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}}
    </Formik>
  );
};
