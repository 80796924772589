import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import {  Layout } from "./components";
import { /* Comments, */ /* Fairs, */ Home, DynamicPage, /* Interests, */ Jobs, Schedule, Distinction } from "./routes";
import { JobDetail } from "./components/jobs/JobDetail";
import { SingleJob } from "./components/jobs/SingleJob";
import { Plan } from "./routes/Plan";


const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/trabajos",
        element: <Jobs />,
        children: '',
      },
      {
        path: "/agenda",
        element: <Schedule />,
      },
      {
        path: "/distinciones",
        element: <Distinction />,
      },
      /* {
        path: "/ferias",
        element: <Fairs />,
      },
      {
        path: "/comentarios",
        element: <Comments />,
      },
      {
        path: "/intereses",
        element: <Interests />,
      }, */
      {
        path: "/plan",
        element: <Plan />
      },
      {
        path: "/trabajos/parte",
        element: <JobDetail />
      },
      {
        path: "/trabajos/:id/detalle",
        element: <SingleJob />
      },
      {
        path: "*",
        element: <DynamicPage />,
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
