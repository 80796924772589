import React from "react";
import { JobsBody } from "../components/jobs/JobsBody";

export const Jobs = () => {
  

  return (
    <main className="flex-shrink-0">
      <JobsBody />
    </main>
  );
};
