import React from "react";
import { Outlet } from "react-router-dom";
import { Logo } from "../assests/images";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { Banner } from "./Banner";

export const Layout = () => {
  return (
    <>
      <div className="navBrand">
        <img src={Logo.default} alt="logo" />
      </div>
      <Navbar />

      <main className="flex-shrink-0">
        <Banner />
      </main>
      <Outlet />
      <Footer />
    </>
  );
};
