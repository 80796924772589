import React, { useEffect } from "react";

export const CardJobDetail = ({ job }) => {
  const capitalizeFirstLetter = (texto) => {
    if(!texto) return ""
    return texto
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    window.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth' 
    });
  }, []);
  return (
    <div className="card datails-container">
      <div className="card-body">
        <ul className="detalleLista">
          <li>
            <p className="trabDetaLabel">Nivel:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.mainData?.schoolLevel?.name)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Modalidad:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.mainData?.gradingModality?.name)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Foco:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.mainData?.curricularFocus?.name)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Establecimiento educativo:</p>{" "}
            <p className="trabDetaText">
              {capitalizeFirstLetter(job?.establishment?.name)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Localidad:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.establishment?.locality)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Ciclo:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.mainData?.classroom?.name)}
            </p>
          </li>
          <li>
            <p className="trabDetaLabel">Curso:</p>
            <p className="trabDetaText">
              {" "}
              {capitalizeFirstLetter(job?.mainCourse?.name)}
            </p>
          </li>
        </ul>
        {/*  <JobForm /> */}
      </div>
    </div>
  );
};
