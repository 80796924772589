import React, { useState } from "react";
import { NavLink } from "react-router-dom";


export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <nav id="myNavbar" className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <button
          className={`navbar-toggler navbar-toggler-button menu-btn ${menuOpen ? 'open' : ''}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded={menuOpen}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <div className="animated-hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navigation">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Inicio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/trabajos"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Trabajos
              </NavLink>
            </li>
            {/*
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/distinciones"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Distinciones
              </NavLink>
            </li>
            */}
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/agenda"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Agenda
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/plan"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Planos
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/ferias"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Ferias de Ciencias Jurisdiccionales e Internacionales
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/intereses"
                activeclassname="active"
                onClick={toggleMenu}
              >
                Te puede interesar
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/comentarios"
                activeclassname="active"
              >
                Comentarios
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};