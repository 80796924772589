import axios from 'axios'
import { showDeveloperMessageConsole } from '../../helpers/random';

// URL DE LA API
const axiosInstance = axios.create({
  // SI deciden usar un servidor que sirva archivos estáticos apuntando a public
  // Agregarle el header Access-Control-Allow-Origin a la responses desde el servidor
  // Y descomentar la siguiente linea
  // baseURL: process.env.REACT_APP_FERIAS_API_URL + '/storage/ferias',

  // Caso contrario utilizar este endpoint que apunta a la app de backoffice
  // Que devuelve una response con el archivo como fallback route (Trait HasFallback)
  baseURL: process.env.REACT_APP_FERIAS_API_URL , //+ '/ferias',
  timeout: 60000
});

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

const requestHandler = (request) => {
  // Modify request here
  request.headers['Content-Type'] = 'application/json';
  request.headers['Accept'] = 'application/json';

  return request;
}

const errorHandler = (error) => {
  return Promise.reject(error)
    .catch((e) => {
      showDeveloperMessageConsole(e);
      throw e;
    })
}

const successHandler = (response) => {
  return response
}

export default axiosInstance;
