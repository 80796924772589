import React from "react";

export const Pagination = ({ metadata, onPageChange }) => {
  const {
    current_page,
    last_page,
    total,
  } = metadata ?? {};

  const totalPages = last_page;


  if (!current_page || !totalPages) {
    return <></>;
  }

  const pageNumbers = [];
  for (let i = 1; i <= 5; i++) {
    const aaa = (current_page - 3) < 0 ? (current_page - 2) : (current_page - 3);
    if ((i + aaa <= totalPages) && (i + aaa > 0)) pageNumbers.push(i + aaa);
  }

 

  return (
    <div className={`row mt-5 ${total === 0 ? "visually-hidden" : ""}`}>
      <div className="col-md-12 col-12">
      <nav className="mt-3">
          <ul className="pagination myCards">
        <li className={`page-item ${current_page === 1 ? "disabled" : ""}`}>
          <button
            className="page-link arrow"
            onClick={() => onPageChange(1)}
            aria-label="First"
          >
            <i className="bi bi-chevron-double-left"></i>
          </button>
        </li>
        <li className={`page-item ${current_page === 1 ? "disabled" : ""}`}>
          <button
            className="page-link arrow"
            onClick={() => onPageChange(current_page - 1)}
            aria-label="Previous"
          >
            <i className="bi bi-chevron-left"></i>
          </button>
        </li>
        {total > 0 && pageNumbers.map((page) => (
          <li
            key={page}
            className={`page-item ${current_page === page ? "active" : ""}`}
            style={{ cursor: 'pointer' }}
          >
            <span className="page-link" onClick={() => {
           
              if(current_page !== page){
                 onPageChange(page)
              };
              
            }} style={{ borderRadius: '16px' }}>
              {page}
            </span>
          </li>
          
        ))}
        <li
          className={`page-item ${
            current_page === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link arrow"
            onClick={() => onPageChange(current_page + 1)}
            aria-label="Next"
          >
            <i className="bi bi-chevron-right"></i>
          </button>
        </li>
        <li className={`page-item ${current_page === last_page ? "disabled" : ""}`}>
          <button
            className="page-link arrow"
            onClick={() => onPageChange(last_page)}
            aria-label="Last"
          >
            <i className="bi bi-chevron-double-right"></i>
          </button>
        </li>
      </ul>
      <div className="pages-showing">
        Mostrando {total > 0 ? (current_page - 1) * 12 + 1 : 0}-
        {Math.min(current_page * 12, total)} de {total}
      </div>
    </nav>
    </div>
    </div>
  );
};

