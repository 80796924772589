import React from "react";
import pagesAPI from "../axios/api/dynamicPages";
import Loading from "../components/Loading";
import { showDeveloperError } from "../helpers/console";

export const DynamicPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [backofficePages, setBackofficePages] = React.useState();

  React.useEffect(() => {
    async function fetchPages() {
      setLoading(true);
      try {
        const paginas = await pagesAPI
          //.get(`frontend-pages/index.json?${Date.now()}`)
          .get(`api/frontend/institutional/index.json?${Date.now()}`)
          .then((res) => res.data);
        setBackofficePages(paginas);
      } catch (error) {
        showDeveloperError(
          "Error al obtener las páginas del backoffice: ",
          error
        );
      } finally {
        setLoading(false);
      }
    }

    fetchPages();

    return () => {
      // second
    };
  }, []);

  if (loading || !backofficePages) {
    return <Loading/>;
  }

  console.log('dynamicPage', backofficePages);

  return <div>DynamicPage</div>;
};
