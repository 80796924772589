import React from "react";
import { Oval } from "react-loader-spinner";

const Loading = ({ height, fullWidth, background }) => {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: height || "65vh",
    width: fullWidth ? "100vw" : "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#f7f7f7",
  };
  return (
    <div style={style}>
      <Oval
        height="80"
        width="80"
        radius="9"
        color="#37BBED"
        secondaryColor="white"
        ariaLabel="loading"
        // wrapperStyle
        // wrapperClass
      />
    </div>
  );
};

export default Loading;
