import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const ImageCarousel = ({ images }) => {
  return (
    <Carousel
      showArrows={true} 
      infiniteLoop={true} 
      autoPlay={true}
      interval={5000}
      className='carrousel'
             
    >
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

