import React, { useEffect, useState, useCallback } from "react";
import parse from "html-react-parser";
import ScheduleService from "../../axios/services/ScheduleService";
import { Pagination } from "../Pagination";
import Loading from "../Loading";

export const ScheduleBody = () => {
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState([]);

  const fetchSchedule = useCallback(
    async (page = undefined, limit = undefined) => {
      setLoading(true);
      try {
        const { data } = await ScheduleService.getSchedule(page, limit);
        setSchedule(data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
    []
  );

  useEffect(() => {
    window.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth' 
    });
    const getText = async () => {
      const { data } = await ScheduleService.getScheduleText();
      setText(data);
    };
    getText();
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  if (loading) {
    return <Loading />;
  }


  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12 col-12">
            <h1 className="sectionName">{parse(text?.title || "")}</h1>
            <hr />
            <p>{parse(text?.text || "")}</p>
          </div>
        </div>

        <div className="row">
          {schedule?.data?.map((e, index) => (
            <div className="col-lg-4 col-md-6 col-12 mt-4" key={index}>
              <a href={e.url}>
                <div className="card convocatorias">
                  <div className="card-header">
                    <h1>{e.date}</h1>
                  </div>
                  <div className="card-body">
                    <h1>{e.title}</h1>
                    <p>
                    {parse(e.original_description)}
                    </p>
                    <p className="horarios">
                      Inicio: {e.startTime} | fin: {e.finishTime}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>

        <Pagination
          metadata={{
            current_page: schedule?.current_page,
            first_page_url: schedule?.first_page_url,
            from: schedule?.from,
            last_page: schedule?.last_page,
            last_page_url: schedule?.last_page_url,
            next_page_url: schedule?.next_page_url,
            path: schedule?.path,
            per_page: schedule?.per_page,
            prev_page_url: schedule?.prev_page_url,
            to: schedule?.to,
            total: schedule?.total,
          }}
          onPageChange={fetchSchedule}
        />
      </div>
    </>
  );
};
