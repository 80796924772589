import React, { useEffect } from "react";
import { Link } from "react-router-dom";


export const JobsBody = () => {
  const links = [
    {
      query: "school_level_id_filter=1",
      title: "Educación inicial",
      icon: "inicial",
    },
    {
      query: "school_level_id_filter=2",
      title: "Educación primaria",
      icon: "primaria",
    },
    {
      query: "school_level_id_filter=3",
      title: "Educación secundaria",
      icon: "secundaria",
    },
    {
      query: "school_level_id_filter=4",
      title: "Educación superior",
      icon: "superior",
    },
    {
      query: "international=1",
      title: "Trabajos internacionales",
      icon: "internacional",
    },
    {
      query: '',
      title: "Todos los trabajos",
      icon: "todos",
    },
  ];

  useEffect(() => {
    window.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth' 
    });
  }, []);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 col-12">
          <h1 className="sectionName">Trabajos</h1>
          <hr />
          <p>
          En este espacio podrán conocer los trabajos distinguidos en las ferias de ciencias jurisdiccionales, 
          desarrollados en el transcurso de este año escolar por equipos de docentes y estudiantes de todos los niveles y modalidades.
          </p>
          {/*
          <p>
          También en esta edición tenemos el agrado de contar con trabajos invitados provenientes de ferias de ciencias de otros países de Latinoamérica:
          </p>
          <ul>
            <li className="mb-4">
              <strong>Chile:</strong> CONGRESO NACIONAL EXPLORA DE INVESTIGACIÓN E INNOVACIÓN ESCOLAR <br/> 
	            Programa Explora - Comisión Nacional de Investigación Científica y Tecnológica, 	Ministerio 	de Educación, República de Chile
            </li>
            <li className="mb-4">
              <strong>Colombia:</strong> ENCUENTRO NACIONAL ONDAS "YO AMO LA CIENCIA" <br/>
              Programa Ondas - Ministerio de Ciencias, Tecnología e Innovación, República de 	Colombia
            </li>
            <li>
              <strong>Ecuador:</strong> ENCUENTRO JUVENIL LATINOAMERICANO DE CIENCIA, TECNOLOGÍA, INNOVACIÓN Y 	EMPRENDIMIENTOS <br/>
              Cámara de Comercio de Ambato y Programa Educativo Aprender a Emprender, 	Ministerio de Educación, República del Ecuador
            </li>
          </ul>
  */}
          <p>
          ¡Qué disfruten la visita! 
          </p>
        </div>
      </div>
      <div className="row mt-4">
        {links.map(({ icon, id, section, title, apiId, query }, index) => (
          <div className="col-lg-2 col-md-3 col-6" key={index}>
            <Link className="edLink" to={`/trabajos/parte?${query}`}>
              <div className={`icon ${icon}`}></div>
              <h1>{title}</h1>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
