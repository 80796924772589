import React, { useEffect, useState, useRef, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CardJobDetail } from "./CardJobDetail";
import JobService from "../../axios/services/JobsService";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import Loading from "../Loading";
import { ImageCarousel } from "../ImageCarousel";
import { CommentCarousel } from "../commentCarousel";
import { CardJobDetailSecundary } from "./CardJobDetailSecundary";

export const SingleJob = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({});
  const [validToken, setValidToken] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validComment, setValidComment] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    commentary: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const getJob = async () => {
      if (id)
        try {
          const {
            data: { data },
          } = await JobService.getOneJobs(id);
          setJob(data);
        } catch (error) {
          console.log(error);
        }
    };
    getJob();
  }, [id]);

  const image = Object.keys(job?.multimedia || {})
    .filter((key) => key.startsWith("image_"))
    .map((key) => job.multimedia[key].url);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formData.commentary.length < 10) {
      setValidComment(true);
    } else {
      setValidComment(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const postData = {
        project_id: job.id,
        commentary: formData.commentary,
        name: formData.name,
        email: formData.email,
      };

      await JobService.commentJobs(postData);
      setFormData({ commentary: "", name: "", email: "" });
      setValidToken(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const captchaRef = useRef(null);

  const handleCaptchaChange = useCallback(() => {
    let token = captchaRef.current?.getValue();

    const verifyCaptcha = async () => {
      if (token) {
        const res = await JobService.postValidateCaptcha(token).then(
          ({ data: { success } }) => {
            setValidToken(success);
          }
        );
      }
    };
    verifyCaptcha();
  }, []);

  if (job && Object.keys(job).length === 0) {
    return <Loading />;
  }
  const capitalizeFirstLetter = (texto) => {
    if (!texto) return "";
    return texto
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const validMatch = (e) => {
    const value = e.target.value;
    var lettersWithAccents = /^[A-Za-zñÑáéíóúüÁÉÍÓÚÜ\s]+$/;
    if (value?.match(lettersWithAccents)) {
      setValidName(true);
    } else {
      setValidName(false);
    }
  };
  /* const regexEmail = /^[a-zA-Z0-9ñÑ._%+-]+@[a-zA-Z0-9ñÑ.-]+\.[a-zA-Z]{2,}$/;
  const validEmail = !!formData?.email?.match(regexEmail); */



  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-12 col-12 d-flex">
          <div className="mx-2">
            <Link to="/">Inicio</Link>
            <span> / </span>
            <Link onClick={() => navigate(-1)}>Trabajos</Link>
          </div>
          <h1 className="sectionName">Trabajos </h1>

          <hr />
        </div>
      </div>
      <div className="d-flex justify-content-center info-container">
        <div>
          <div className="titleTwo">
            <h2 className="mb-3">"{job?.title}"</h2>
            <h4 className="mb-3">
              {job?.country?.toLowerCase() !== "argentina" &&
              job?.country !== null
                ? job?.country
                : job?.jurisdiction}
            </h4>
            <div style={{ display: "flex" }}>
              <p className="trabDetaLabel">Ubicación:</p>
              <p className="trabDetaText">
                {capitalizeFirstLetter(job?.ubication || "")}
              </p>
            </div>
          </div>
          <div className="cardJobtwo">
            <div className="col-md-3 col-12  mt-3 order-md-1 order-2 cardJobtwo-div cardJobtwo-div-one">
              <CardJobDetail job={job} />
            </div>
            {job.secondaryData && (
              <div className="col-md-3 col-12  mt-3 order-md-1 order-2 cardJobtwo-div cardJobtwo-div-two">
                <CardJobDetailSecundary job={job} />
              </div>
            )}
          </div>
          <div className="col-md-8 mx-auto mt-3 text-center d-grid justify-content-center">
            <div style={{ marginLeft: "20px" }}>
              <p className="trabajoTexto" style={{ display: "flex" }}>
                {job?.summary}
              </p>
              {job?.teachers.map((e, index) => (
                <div style={{ display: "flex" }} key={index}>
                  <p className="trabDetaLabel">Docente:</p>
                  <p className="trabDetaText">
                    {capitalizeFirstLetter(e.name || "")}
                  </p>
                </div>
              ))}
            </div>
            <div className="ytVidContainer mt-4 p-3">
              <div className="ratio ratio-16x9">
                <ReactPlayer
                  url={job?.multimedia?.url}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
              <div>
                <ImageCarousel images={image} />
              </div>

              <div className="row d-flex flex-column align-items-center">
                <h5 className="text-center">
                  Descargar la presentación del Trabajo
                </h5>
                <button
                  className="btn btn-primary btn-custom"
                  disabled={!job?.presentation?.url}
                >
                  <a
                    href={job?.presentation?.url || "#"}
                    download={job?.presentation?.name || ""}
                    onClick={(e) => {
                      if (!job?.presentation?.url) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Descargar
                  </a>
                </button>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="formJobs w-100 mt-2">
          <div className="titleFormJobs">
            <h1 style={{ color: "#707070" }}>¿Que te parecio el trabajo? </h1>
            <p className="sectionName text-center">Esperamos tu comentario </p>
          </div>
          <form onSubmit={handleSubmit} style={{ padding: "0px 20px" }}>
            <div className="mb-3">
              <label htmlFor="commentary" className="form-label">
                Mensaje*
              </label>
              <textarea
                className="form-control"
                id="commentary"
                name="commentary"
                value={formData.commentary}
                onChange={handleInputChange}
                required
                minLength={10}
                maxLength={255}
              />
            </div>
            <small style={{ color: "red" }}>
              {formData?.commentary &&
                validComment &&
                "El mensaje debe contener al menos 10 caracteres"}
            </small>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nombre*
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nombre (ingresar solo letras)"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                onKeyUp={validMatch}
                required
                minLength={2}
                maxLength={50}
              />
              <small style={{ color: "red" }}>
                {formData?.name &&
                  !validName &&
                  "El nombre no puede contener números"}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Correo Electronico*
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}"
                required
              />
              {/* <small style={{ color: "red" }}>
                {formData?.email &&
                  !validEmail &&
                  "El email es invalido"}
              </small> */}
            </div>

            <div className="mb-3">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={handleCaptchaChange}
                ref={captchaRef}
              />
            </div>
            {loading ? (
              <button class="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Enviando...
              </button>
            ) : (
              <button
              disabled={
                !validToken ||
                (formData.commentary === "" &&
                  formData.name === "" &&
                  formData.email === "") ||
                (formData?.name && !validName)
              }
                type="submit"
                className="btn btn-primary"
              >
                Enviar
              </button>
            )}
          </form>
        </div>
      </div>
      <div className="my-4 custom-margin">
        <h5 className="fw-bold text-center my-3" style={{ color: "#707070" }}>
          Comentarios
        </h5>
        <CommentCarousel comment={job} />
      </div>
    </div>
  );
};
