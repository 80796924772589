import React from "react";
import PlanBody from "../components/plan/PlanBody";

export const Plan = () => {
  return (
    <main className="flex-shrink-0">
      <PlanBody />
    </main>
  );
};
